import React from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'
import SEOWebpage from '../../components/SEO/webpage'
import MaskedInput from 'react-text-mask'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

export default function Careers() {

  const pageData = useStaticQuery(graphql`
    query CareersQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      graphCmsCareersPage {
        id
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        createdAt
        publishedAt
        updatedAt
        slug
        title
        leftImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        topContent {
          html
        }
      }
    }
  `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + "/" + pageData.graphCmsCareersPage.slug + "/";
  const image = getImage(pageData.graphCmsCareersPage.leftImage);

  return (
    <Layout>
      <Head
        title={pageData.graphCmsCareersPage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsCareersPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOWebpage
        title={pageData.graphCmsCareersPage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsCareersPage.pageHeaderSeo.metaDescription}
        url={pageUrl}
        date_published={pageData.graphCmsCareersPage.createdAt}
        date_modified={pageData.graphCmsCareersPage.updatedAt}
      />
      <div className="pageSection">
        <h1>{pageData.graphCmsCareersPage.title}</h1>
        <div className="center-text" dangerouslySetInnerHTML={{ __html: pageData.graphCmsCareersPage.topContent.html }} ></div>
        <div className="boxHalfRow">
          <div className="boxHalf">
            <form autoComplete="on" action="https://formsubmit.co/f1516c1c8a1dacc4e86bfb7f3292946f" encType="multipart/form-data" method="POST">
              <fieldset>
                <label htmlFor="FirstName">First Name</label>
                <input placeholder="First Name" htmlFor="given-name" id="FirstName" type="text" name="FirstName" required />
              </fieldset>
              <fieldset>
                <label htmlFor="LastName">Last Name</label>
                <input placeholder="Last Name" htmlFor="family-name" id="LastName" type="text" name="LastName" required />
              </fieldset>
              <fieldset>
                <label htmlFor="PhoneNumber">Phone Number</label>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  className="form-control"
                  placeholder="Phone Number"
                  guide={true}
                  name="PhoneNumber"
                  type="tel"
                  id="PhoneNumber"
                  required
                />
              </fieldset>
              <fieldset>
                <label htmlFor="email">Email Address</label>
                <input placeholder="Email" id="email" type="email" name="email" required />
              </fieldset>
              <fieldset>
                <label className="customUpload" htmlFor="attachment">Attach Resume</label>
                <input id="attachment" type="file" name="attachment" required />
              </fieldset>
              <input type="hidden" name="_next" value="https://www.valleycommercialservice.com/contact-success/" />
              <input type="hidden" name="_template" value="table" />
              <input type="hidden" name="_subject" value="New: Careers Page Form Submission" />
              <fieldset>
                <button className="main-button" type="submit">Send</button>
              </fieldset>
            </form>
          </div>
          <div className="boxHalf">
            <GatsbyImage image={image} alt="test" />
          </div>
        </div>
      </div>
    </Layout>
  )
}
